import { Stack, Spinner } from "@chakra-ui/react";

export default function index() {
  return (
    <Stack
      flex={1}
      h="full"
      bg="blackLight"
      align={"center"}
      justify="center"
      spacing={6}
    >
      <img src="/favicon.ico" />
      <Spinner color="yellowLight" size="lg" />
    </Stack>
  );
}
