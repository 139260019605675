import { ReactNode } from "react";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  Stack,
  Text,
  Container,
  Collapse,
  Divider
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link, Link as RouterLink, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Bell from "../../assets/icons/Bell";
import LogoutIcon from "../../assets/icons/LogoutIcon";
import HorizontalLogo from "../Logo/HorizontalLogo";

interface ILink {
  href: string;
  title: string;
}
const Links: Array<ILink> = [
  {
    href: "/newsroom",
    title: "Newsroom",
  },
  {
    href: "/trainers",
    title: "Trainers",
  },
  {
    href: "/calendar",
    title: "Calendar",
  },
  {
    href: "/feedback",
    title: "Feedback",
  },
  {
    href: "/contact",
    title: "Contact",
  },
];

const NavLink = ({ href, children }: { href: string; children: ReactNode }) => {
  const { pathname } = useLocation();
  return (
    <Text
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        color: "yellowLight",
      }}
      color={pathname.includes(href) ? "yellowLight" : "blackLight"}
    >
      <RouterLink to={href}>{children}</RouterLink>
    </Text>
  );
};

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box bg={"greenMid"}>
      <Container maxW={"7xl"}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={
              isOpen ? (
                <CloseIcon color="#EAD3AB" />
              ) : (
                <HamburgerIcon fontSize={"1.3rem"} />
              )
            }
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
            variant="ghost"
            bg="transparent"
            _active={{}}
            _focus={{}}
          />
          <Box>
            <HorizontalLogo />
          </Box>
          <HStack spacing={8} alignItems={"center"}>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {Links.map((link: ILink) => (
                <NavLink key={link.title} href={link.href}>
                  {link.title}
                </NavLink>
              ))}
            </HStack>
            <NavbarButtons />
          </HStack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Container>
    </Box>
  );
}

const MobileNav = () => {
  return (
    <Box pb={4} display={{ md: "none" }}>
      <Divider borderColor={"yellowLight"} mb="4"/>
      <Stack as={"nav"} spacing={4}>
        {Links.map((link) => (
          <NavLink key={link.title} href={link.href}>
            {link.title}
          </NavLink>
        ))}
      </Stack>
    </Box>
  );
};

const NavbarButtons = () => {
  const { logout } = useAuth0();

  return (
    <HStack align={"center"}>
      <Link to="/notifications">
        <IconButton
          icon={<Bell />}
          aria-label="Notifications"
          variant={"ghost"}
          _hover={{
            "& path": { fill: "yellowLight" },
          }}
        />
      </Link>
      <IconButton
        icon={<LogoutIcon />}
        aria-label="Logout"
        onClick={() => logout()}
        variant={"ghost"}
        _hover={{
          "& path": { fill: "yellowLight" },
        }}
      />
    </HStack>
  );
};
