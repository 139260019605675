import {
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Controller,
  RegisterOptions,
  useFormContext,
  useFormState,
} from "react-hook-form";
import _ from "lodash";
import { ReactNode } from "react";
import { IoAlertOutline } from "react-icons/io5";

export interface IInputField {
  name: string;
  label?: string;
  rules?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  placeholder?: string;
  icon?: ReactNode;
  bg?: "darkGrey" | "light-blue-light";
  hidden?: boolean;
  disabled?: boolean;
  type: string;
  height?: string | undefined;
  options?: { id: number; value: string; label: string }[];
}

const TextAreaField = ({
  name,
  label,
  rules,
  placeholder,
  icon,
  bg,
  hidden = false,
  disabled = false,
  type = "text",
}: IInputField) => {
  const { control, watch } = useFormContext();
  const { errors } = useFormState({ control });
  const bgColorMode = useColorModeValue(
    !hidden && _.get(errors, name) ? "red.50" : bg,
    !hidden && _.get(errors, name) ? "dark-blue-dark" : "dark-blue-dark"
  );
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={_.get(watch(), name)}
        render={({ field: { ref, ...rest } }) => (
          <InputGroup>
            <Textarea
              bg={"blackLight"}
              hidden={hidden}
              // type={type}
              variant={"primary"}
              disabled={disabled}
              placeholder={placeholder}
              // inputref={ref}
              {...rest}
              //   label={label}
              // border={"none"}
              // size={"lg"}
              // borderRadius={"8px"}
              fontWeight={"normal"}
              // _placeholder={{
              //   fontWeight: "light",
              //   color: "#bbb",
              //   fontSize: { base: "0.9rem", "2xl": "1rem" },
              // }}
              border="1px solid"
              // borderWidth={!hidden && _.get(errors, name) ? "1px" : "0px"}
              // borderColor={
              //   !hidden && _.get(errors, name) ? "red.100" : "transparent"
              // }
              // height={{ base: "2.5rem", "2xl": "3rem" }}
              rounded="1rem"
              borderColor={
                !hidden && _.get(errors, name) ? "#eb5757" : "greenExtraLight1"
              }
              _placeholder={{ color: "yellowLight" }}
              size="lg"
              rows={4}
              color="yellowLight"
              _focus={{}}
              _hover={{}}
              resize="none"
            ></Textarea>
            {!hidden && _.get(errors, name) ? (
              <InputLeftElement
                pointerEvents="none"
                placement="right"
                h={"full"}
              >
                <IoAlertOutline style={{ color: "#eb5757" }} />
              </InputLeftElement>
            ) : null}
          </InputGroup>
        )}
        rules={rules}
      />
      {/* {!hidden && (
        <Text color="red" fontSize="sm">
          {_.get(errors, name) ? _.get(errors, `${name}.message`) : null}
        </Text>
      )} */}
    </div>
  );
};

export default TextAreaField;
