import { Button, HStack } from "@chakra-ui/react";

export const ButtonGroup = ({ value }: { value: boolean }) => {
  return (
    <HStack flex={1} justify="center">
      <Button
        variant={"outline"}
        rounded="full"
        borderColor={"yellowLight"}
        flex={1}
        maxW="110px"
        _hover={{ bg: "yellowLight", color: "blackLight" }}
        _active={{}}
        color={value ? "blackLight" : "yellowLight"}
        bg={value ? "yellowLight" : "blackLight"}
      >
        Yes
      </Button>
      <Button
        variant={"outline"}
        rounded="full"
        borderColor={"yellowLight"}
        flex={1}
        maxW="110px"
        _hover={{ bg: "yellowLight", color: "blackLight" }}
        _active={{}}
        //   @ts-ignore
        color={value === false ? "blackLight" : "yellowLight"}
        //   @ts-ignore
        bg={value === false ? "yellowLight" : "blackLight"}
      >
        No
      </Button>
    </HStack>
  );
};
