import axiosInstance from "../utils/axiosInstance";

interface UseMutationUploadFileVariables {
  token: string;
  file: File;
  imageId: number;
}

interface ProtectedMutation {
  id: number;
  token: string;
  articleType?: 'external' | 'internal';
}

export const uploadFile = async (variables: UseMutationUploadFileVariables) => {
  const { file, token, imageId } = variables;

  let fd = new FormData();
  fd.append("file", file);

  return await axiosInstance({
    method: "post",
    url: `/media`,
    data: fd,
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => ({ ...res.data, imageId }));
};

export const createExternalArticle = async (data: any) => {
  const { token, ...rest } = data;
  return await axiosInstance({
    method: "post",
    url: `/external-article/create`,
    data: { ...rest },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const fetchExternalArticles = async (page: number, token: string) => {
  return await axiosInstance({
    method: "get",
    url: `/external-article/all?page=${page}&limit=20`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const togglePublishArticle = async ({
  id,
  token,
  articleType = "external"
}: ProtectedMutation) => {
  return await axiosInstance({
    method: "patch",
    url: articleType === "external" ? `/external-article/${id}` : `/article/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const deleteArticle = async ({ id, token, articleType = "external" }: ProtectedMutation) => {
  return await axiosInstance({
    method: "delete",
    url: articleType === "external" ? `/external-article/${id}` : `/article/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const createNewArticle = async (data: any) => {
  const { token, ...rest } = data;
  return await axiosInstance({
    method: "post",
    url: `/article/create`,
    data: { ...rest },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const fetchArticles = async (page: number, token: string) => {
  return await axiosInstance({
    method: "get",
    url: `/article/all?page=${page}&limit=20`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const fetchOnePressRelease = async (id: number|undefined, token: string) => {
  return await axiosInstance({
    method: "get",
    url: `/article/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};

export const updatePressRelease = async ({id, data, token}: any) => {
  return await axiosInstance({
    method: "patch",
    url: `/article/update/${id}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res: any) => res.data);
};