import { useSearchParams } from "react-router-dom";
import PalaceInThePressForm from "../components/NewsroomForms/PalaceInThePressForm";
import PressReleasesForm from "../components/NewsroomForms/PressReleasesForm";

export default function CreateNewsroom() {
  const [searchParams] = useSearchParams();

  if (searchParams.get("type") === "palace-in-the-press") {
    return <PalaceInThePressForm />;
  } else {
    return <PressReleasesForm />;
  }
}
