export const palaceInThePressFields = [
  {
    type: "text",
    name: "title",
    placeholder: "Article title",
    rules: {
      required: true,
    },
  },
  {
    type: "text",
    name: "articleUrl",
    placeholder: "Article link",
    rules: {
      required: true,
    },
  },
  {
    type: "text",
    name: "writtenBy",
    placeholder: "Written by",
    rules: {
      required: true,
    },
  },
  {
    type: "text",
    name: "writterUrl",
    placeholder: "Writer website",
    rules: {
      required: true,
    },
  },
];

export const pressReleasesFields = [
  {
    type: "select",
    name: "lang",
    placeholder: "Article language",
    options: [
      {
        label: "English",
        value: "en",
      },
      {
        label: "Chinese",
        value: "ch",
      },
    ],
    rules: {
      required: true,
    },
  },
  {
    type: "text",
    name: "title",
    placeholder: "Article title",
    rules: {
      required: true,
    },
  },
  {
    type: "text",
    name: "subTitle",
    placeholder: "Article subtitle",
    rules: {
      required: true,
    },
  },
];
