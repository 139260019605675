import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  ButtonGroup,
  Grid,
  GridItem,
  IconButton,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useRef, useState } from "react";
import { MdPublish, MdUnpublished, MdDelete, MdEditNote } from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import CheckIcon from "../../assets/icons/CheckIcon";
import {
  deleteArticle,
  fetchArticles,
  togglePublishArticle,
} from "../../services/newsroom.services";
import Dialog from "../DialogAlert";
import TableHeader from "../Table/TableHeader";
import TableFooter from "../TableFooter";
import Toast from "../Toast";

const columns = ["Thumbnail", "title", "Subtitle", "Created at", "Action"];

interface IArticle {
  id: number;
  title: string;
  subTitle: string;
  thumbnail: string;
  content: string;
  readTime: number;
  isPublished: boolean;
  createdAt: string;
  images: Array<string>;
}

export default function PressReleasesTable() {
  const { getAccessTokenSilently } = useAuth0();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );

  const { data, isLoading } = useQuery(
    [`press-releases`, page],
    async () => {
      const token = await getAccessTokenSilently();
      return fetchArticles(page, token);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <Grid
        gridTemplateColumns={`120px repeat(${
          columns.length - 2
        }, minmax(135px, 1fr)) 180px`}
        gap="0"
        overflowX="auto"
        className="feedback-grid"
      >
        <TableHeader columns={columns} />
        {isLoading ? (
          <Stack align={"center"} justify="center" gridColumn={"1/6"} h="20">
            <Spinner color="yellowLight" />
          </Stack>
        ) : (
          <Grid
            gridTemplateColumns={`120px repeat(${
              columns.length - 2
            }, minmax(135px, 1fr)) 180px`}
            gridColumn={"1/6"}
            gap="0"
            border="1px solid #96A090"
            borderRadius={"xl"}
            overflow="hidden"
          >
            {data?.items?.map((article: IArticle, index: number) => (
              <TableRow
                key={article.id}
                article={article}
                isLast={index === data.items.length - 1}
              />
            ))}
          </Grid>
        )}
      </Grid>
      <TableFooter
        currentPage={page}
        setPage={setPage}
        totalPages={data?.meta?.totalPages ?? "-"}
      />
    </>
  );
}

const TableRow = ({
  article,
  isLast,
}: {
  article: IArticle;
  isLast: boolean;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const toastIdRef = useRef<any>();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { mutateAsync, isLoading } = useMutation(togglePublishArticle, {
    onSuccess: (res) => {
      const { isPublished } = res;
      toastIdRef.current = toast({
        position: "top",
        duration: 2000,
        render: () => (
          <Toast
            toast={toast}
            message={isPublished ? "Article published" : "Article unpublished"}
            toastIdRef={toastIdRef}
            icon={<CheckIcon />}
          />
        ),
      });
      queryClient.invalidateQueries(`press-releases`);
    },
  });
  const { mutateAsync: removeArticle, isLoading: removingAritcle } =
    useMutation(deleteArticle, {
      onSuccess: () => {
        queryClient.invalidateQueries(`press-releases`);
        toastIdRef.current = toast({
          position: "top",
          duration: 2000,
          render: () => (
            <Toast
              toast={toast}
              message={"Article deleted"}
              toastIdRef={toastIdRef}
              icon={<CheckIcon />}
            />
          ),
        });
      },
    });

  return (
    <>
      <GridItem
        my="auto"
        bg={"transparent"}
        fontWeight={"normal"}
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="5"
        alignItems={"center"}
        display="flex"
      >
        <Tag
          bg="yellowDark"
          mr="2"
          minWidth={"35px"}
          textAlign="center"
          alignItems={"center"}
          justifyContent="center"
        >
          {article.isPublished ? "⭐" : "✰"}
        </Tag>
        <Avatar name={article.title} src={article.thumbnail} border="none" size={"sm"}/>
      </GridItem>
      <GridItem
        my="auto"
        bg={"transparent"}
        fontWeight={"normal"}
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="6"
      >
        <Text color="yellowLight" textTransform={"uppercase"} noOfLines={1}>
          {article.title}
        </Text>
      </GridItem>
      <GridItem
        my="auto"
        bg={"transparent"}
        fontWeight={"normal"}
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="6"
      >
        <Text color="yellowLight" textTransform={"uppercase"} noOfLines={1}>
          {article.subTitle}
        </Text>
      </GridItem>
      <GridItem
        my="auto"
        bg={"transparent"}
        fontWeight={"normal"}
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        px="2"
        py="6"
      >
        <Text color="yellowLight" textTransform={"uppercase"} noOfLines={1}>
          {article.createdAt}
        </Text>
      </GridItem>
      <GridItem
        my="auto"
        bg={"transparent"}
        fontWeight={"normal"}
        borderBottom={isLast ? "none" : "1px solid #96A090"}
        borderLeft={"1px solid #96A090"}
        px="2"
        py="4"
      >
        <ButtonGroup
          variant="link"
          h="full"
          w={"full"}
          justifyContent="space-between"
          alignItems={"center"}
          spacing={0}
        >
          <Dialog
            question="Are you sure?"
            description={
              article.isPublished
                ? "This article will be unpublished."
                : "This article will be published in the website."
            }
            isLoading={isLoading}
            onClick={async () => {
              const token = await getAccessTokenSilently();
              return mutateAsync({ id: article.id, token, articleType: "internal" });
            }}
          >
            <Tooltip
              hasArrow
              label={article.isPublished ? "Unpublish" : "Publish"}
            >
              <IconButton
                _hover={{ bg: "blackAlpha.400" }}
                _active={{ bg: "blackAlpha.400" }}
                icon={
                  article.isPublished ? (
                    <MdUnpublished fontSize={"1.4rem"} />
                  ) : (
                    <MdPublish fontSize={"1.4rem"} />
                  )
                }
                aria-label={article.isPublished ? "Unpublish" : "Publish"}
                variant={"ghost"}
                color="yellowDark"
              />
            </Tooltip>
          </Dialog>
          <Tooltip
              hasArrow
              label={"Update"}
            >
              <IconButton
                _hover={{ bg: "blackAlpha.400" }}
                _active={{ bg: "blackAlpha.400" }}
                icon={<MdEditNote fontSize={"2rem"} />}
                aria-label={"Update"}
                variant={"ghost"}
                color="yellowDark"
                onClick={() => navigate(`/newsroom/update/press-release/${article.id}`)}
              />
            </Tooltip>
          <Dialog
            question="Are you sure?"
            description={"This article will be deleted permanently."}
            isLoading={removingAritcle}
            onClick={async () => {
              const token = await getAccessTokenSilently();
              return removeArticle({ id: article.id, token, articleType: "internal" });
            }}
          >
            <Tooltip hasArrow label={"Delete article"}>
              <IconButton
                _hover={{ bg: "blackAlpha.400" }}
                _active={{ bg: "blackAlpha.400" }}
                icon={<MdDelete fontSize={"1.4rem"} />}
                aria-label="Update article"
                variant={"ghost"}
                color="yellowDark"
              />
            </Tooltip>
          </Dialog>
          {/* <Tooltip hasArrow label={"Visite article"} placement="left">
            <a
              href={article.articleUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                _hover={{ bg: "blackAlpha.400" }}
                _active={{ bg: "blackAlpha.400" }}
                icon={<ExternalLinkIcon fontSize={"1.4rem"} />}
                aria-label="see details"
                variant={"ghost"}
                color="yellowDark"
              />
            </a>
          </Tooltip> */}
        </ButtonGroup>
      </GridItem>
    </>
  );
};
