import CheckBoxField from "./CheckboxField";
import FileField from "./FileField";
import NumberField from "./NumberField";
import SelectField from "./SelectField";
import TextAreaField from "./Textarea";
import TextField from "./TextField";

const Field = ({ type, children, ...rest }: any) => {
  return (
    <>
      {type === "text" ? (
        <TextField {...rest} />
      ) : type === "select" ? (
        <SelectField {...rest} />
      ) : type === "textarea" ? (
        <TextAreaField {...rest} />
      ) : type === "number" ? (
        <NumberField {...rest} />
      ) : type === "checkbox" ? (
        <CheckBoxField {...rest} />
      ) : type === "file" ? (
        <FileField {...rest}>{children}</FileField>
      ) : null}
    </>
  );
};

export default Field;
