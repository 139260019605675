import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useAuth0 } from "@auth0/auth0-react";

const EditorContainer = ({editorSate, setEditorSate}: any) => {
  const { getAccessTokenSilently } = useAuth0();

  const onEditorStateChange = (editorState: any) => {
    setEditorSate(editorState);
  };

  const uploadImageCallBack = (file: any) => {
    return new Promise(async (resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const token = await getAccessTokenSilently();

      xhr.open("POST", `${process.env.REACT_APP_API_URL}/media`);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      const data = new FormData();
      data.append("file", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        resolve({ data: { link: response.url } });
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  };

  return (
    <div className="editor">
      <Editor
        editorState={editorSate}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="wrapper-class"
        toolbarClassName="toolbar-class"
        editorClassName="editor-class"
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: false },
          },
        }}
      />
    </div>
  );
};

export default EditorContainer;
