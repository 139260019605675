import { useAuth0 } from "@auth0/auth0-react";
import { Box, Container, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import {
  fetchOneContact,
  setContactAsSeen,
} from "../services/contact.services";
import Loading from "../components/Loading";
import PageNotFound from "./NotFound";
import { IContact } from "./Contact";
import moment from "moment";
import { useEffect } from "react";

export default function ContactDetails() {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams<{ id: string }>();

  const { data, isLoading, isError } = useQuery(
    `contact-${id}`,
    async () => {
      const token = await getAccessTokenSilently();
      return fetchOneContact(id, token);
    },
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );


  return (
    <Flex direction={"column"} bg="blackLight" flex={1}>
      <PageHeader title="Received contact" goBackUrl={"/contact"} />
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <PageNotFound />
      ) : (
        <ContactDetailsContent contact={data} />
      )}
    </Flex>
  );
}

const ContactDetailsContent = ({ contact }: { contact: IContact }) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(setContactAsSeen, {
    onSuccess: () => queryClient.invalidateQueries("contact"),
  });

  useEffect(() => {
    const setContact = async () => {
      const token = await getAccessTokenSilently();
      return mutate({ id: contact.id, token, setToRead: "true" });
    };
    setContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxW={"7xl"} mt="6">
      <Stack>
        <Stack align={"flex-start"} justify="space-between" direction={{base: "column-reverse", md: "row"}}>
          <Stack spacing={4}>
            <Stack spacing={-1}>
              <Text
                fontWeight={"bold"}
                color="yellowDark"
                textTransform={"uppercase"}
                fontSize="sm"
              >
                From:
              </Text>
              <Text fontWeight={"light"} fontSize="lg">
                {contact.name}
              </Text>
              <Text fontWeight={"light"} fontSize="lg">
                {contact.email}
              </Text>
            </Stack>
            <Stack spacing={0}>
              <Text
                fontWeight={"bold"}
                color="yellowDark"
                textTransform={"uppercase"}
                fontSize="sm"
              >
                Subject:
              </Text>
              <Text fontWeight={"light"} fontSize="lg">
                {contact.subject}
              </Text>
            </Stack>
          </Stack>
          <HStack>
            <Text
              fontWeight={"bold"}
              color="yellowDark"
              textTransform={"uppercase"}
              fontSize="sm"
            >
              Sent on:
            </Text>
            <Text fontSize="sm">
              {moment(contact.created_at).format("DD/MM/YYYY")}
            </Text>
          </HStack>
        </Stack>
        <Stack
        // bg="#141a22" rounded="xl"
        >
          <Text fontWeight={"bold"} color="yellowDark" fontSize="sm" textTransform={"uppercase"}>
            Message:
          </Text>
          <Box
            border="1px solid"
            borderColor={"greenExtraLight1"}
            rounded="xl"
            borderTopLeftRadius={"0px"}
            p="2"
            minHeight={"10rem"}
          >
            <Text fontWeight={"light"} fontSize="lg">
              {contact.message}
            </Text>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};
