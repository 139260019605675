import { useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import PageHeader from "../PageHeader";
import { useMutation } from "react-query";
import {
  createExternalArticle,
  uploadFile,
} from "../../services/newsroom.services";
import UploadImages from "./UploadImages";
import { FormProvider, useForm } from "react-hook-form";
import Field from "../Form";
import { palaceInThePressFields } from "../../utils/fields";
import Toast from "../Toast";
import { RiErrorWarningLine } from "react-icons/ri";
import { useAuth0 } from "@auth0/auth0-react";
import CheckIcon from "../../assets/icons/CheckIcon";
import { useNavigate } from "react-router-dom";

export default function PalaceInThePressForm() {
  const [images, setImages] = useState<Array<any>>([]);
  const methods = useForm({
    defaultValues: {
      title: "",
      articleUrl: "",
      writtenBy: "",
      writterUrl: "",
      readTime: 3,
    },
  });
  const toast = useToast();
  const toastIdRef = useRef<any>();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const handleImage = (e: any, imageId: number) => {
    const preview = URL.createObjectURL(e.target.files[0]);
    const _images = images.map((el: any) =>
      el.id === imageId
        ? { ...el, preview: preview, file: e.target.files[0] }
        : el
    );
    setImages(_images);
  };

  const { mutateAsync } = useMutation(uploadFile, {
    onSuccess: (res) =>
      setImages(
        images.map((el: any) =>
          el.id === res.imageId
            ? { ...el, isLoading: false, isCompleted: true, imgUrl: res.url }
            : el
        )
      ),
  });

  const { mutate: createArticle, isLoading: creatingArticle } = useMutation(
    createExternalArticle,
    {
      onSuccess: () => {
        toastIdRef.current = toast({
          position: "top",
          duration: 2000,
          render: () => (
            <Toast
              toast={toast}
              message={"Article created"}
              toastIdRef={toastIdRef}
              icon={<CheckIcon />}
            />
          ),
        });
        navigate("/newsroom?tab=palace-in-the-press");
      },
    }
  );

  const onSubmit = async (data: any, options: { isPublished: boolean }) => {
    const imgUrls = images.map((image: any) => image.imgUrl);
    const token = await getAccessTokenSilently();

    if (images.some((image: any) => !image.isCompleted)) {
      toastIdRef.current = toast({
        position: "top",
        duration: 2000,
        render: () => (
          <Toast
            toast={toast}
            message={"Please upload all images"}
            toastIdRef={toastIdRef}
            icon={<RiErrorWarningLine />}
            status="error"
          />
        ),
      });
    } else {
      createArticle({
        ...data,
        images: imgUrls,
        isPublished: options.isPublished,
        token,
      });
    }
  };

  return (
    <Box bg="blackLight" flex={1}>
      <PageHeader
        title="Newsroom"
        goBackUrl={`/newsroom?tab=palace-in-the-press`}
      />
      <Container maxW={"7xl"} mt="6">
        <Stack
          as="form"
          spacing={4}
          onSubmit={methods.handleSubmit((x: any) =>
            onSubmit(x, { isPublished: true })
          )}
        >
          <FormProvider {...methods}>
            {palaceInThePressFields.map((field: any) => (
              <Stack spacing={-1} key={field.name}>
                <FormLabel color="yellowDark">{field.placeholder}</FormLabel>
                <Field {...field} />
              </Stack>
            ))}
          </FormProvider>
          <UploadImages
            images={images}
            setImages={setImages}
            handleImage={handleImage}
            mutateAsync={mutateAsync}
          />
          <Stack
            direction={{ base: "row", md: "row" }}
            alignSelf={{ base: "center", md: "flex-end" }}
          >
            <Button
              alignSelf={"flex-end"}
              px="6"
              variant={"ghost"}
              border="1px solid"
              borderColor="blackLight"
              color="yellowLight"
              rounded="full"
              _hover={{ bg: "blackAlpha.400" }}
              onClick={() =>
                onSubmit(methods.getValues(), { isPublished: false })
              }
              isLoading={creatingArticle}
            >
              Save in draft
            </Button>
            <Button
              type="submit"
              px="6"
              variant={"outline"}
              borderColor="greenExtraLight1"
              color="yellowLight"
              rounded="full"
              _hover={{ bg: "greenDark" }}
              isLoading={creatingArticle}
            >
              Publish
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
