import { Route, Routes, useLocation } from "react-router-dom";
import PageNotFound from "../pages/NotFound";
import Home from "../pages/Home";

export default function HomeContainer() {
  const { pathname } = useLocation();

  return (
    <Routes>
      <Route path={`${pathname}`} element={<Home />} />
      <Route path={`${pathname}/*`} element={<PageNotFound />} />
    </Routes>
  );
}
