
export default function ArrowLeft() {
    return (
      <svg
        width="48"
        height="55"
        viewBox="0 0 48 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="23.5" cy="24" r="22.5" fill="#242A34" />
        <path
          d="M23.9941 0C17.7045 0.00654284 11.6683 2.45293 7.18203 6.81375C2.69572 11.1746 0.117098 17.102 0 23.3229V31.0079C0 37.3047 2.52856 43.3436 7.02944 47.7961C11.5303 52.2486 17.6348 54.75 24 54.75C30.3652 54.75 36.4697 52.2486 40.9706 47.7961C45.4714 43.3436 48 37.3047 48 31.0079V23.7304C47.986 17.438 45.4517 11.4074 40.9523 6.95962C36.4529 2.5118 30.355 0.00922707 23.9941 0ZM23.9941 45.1728C19.7106 45.1728 15.5233 43.9163 11.9618 41.5621C8.40017 39.2079 5.62425 35.8618 3.98503 31.9469C2.34581 28.032 1.91692 23.7241 2.75259 19.5681C3.58825 15.4121 5.65095 11.5945 8.67983 8.59819C11.7087 5.60186 15.5677 3.56133 19.7689 2.73465C23.9701 1.90796 28.3247 2.33225 32.2822 3.95385C36.2396 5.57545 39.622 8.32154 42.0018 11.8448C44.3816 15.3682 45.6518 19.5105 45.6518 23.7479C45.6409 29.4252 43.355 34.8667 39.2953 38.8795C35.2355 42.8924 29.7331 45.1492 23.9941 45.1554V45.1728Z"
          fill="#EAD3AB"
        />
        <path
          d="M26.4037 15L17 23.9979L26.4037 33L28 31.147L20.5373 23.9979L28 16.8572L26.4037 15Z"
          fill="#EAD3AB"
        />
      </svg>
    );
  }
  