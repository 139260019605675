

export default function ArrowLeftSmall() {
  return (
    <svg
      id="test"
      xmlns="http://www.w3.org/2000/svg"
      width="29.867"
      height="34.067"
      viewBox="0 0 29.867 34.067"
    >
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="14"
        cy="14"
        r="14"
        transform="translate(0.622 0.933)"
        fill="#77866e"
      />
      <path
        id="Path_1"
        data-name="Path 1"
        d="M14.93,0A15.032,15.032,0,0,0,4.469,4.24,14.712,14.712,0,0,0,0,14.512v4.782A14.693,14.693,0,0,0,4.374,29.74a15.049,15.049,0,0,0,21.119,0,14.693,14.693,0,0,0,4.374-10.446V14.766A14.719,14.719,0,0,0,25.482,4.33,15.042,15.042,0,0,0,14.93,0Zm0,28.108a13.58,13.58,0,0,1-7.487-2.247A13.363,13.363,0,0,1,2.48,19.878,13.227,13.227,0,0,1,5.4,5.35,13.557,13.557,0,0,1,26.135,7.37a13.2,13.2,0,0,1-1.684,16.822A13.565,13.565,0,0,1,14.93,28.1Z"
        fill="#ead3ab"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M22.851,15,17,20.6l5.851,5.6.993-1.153L19.2,20.6l4.644-4.443Z"
        transform="translate(-6.422 -5.667)"
        fill="#ead3ab"
      />
    </svg>
  );
}
