export default function CheckIcon({size="sm"}:{size?: "sm"|"lg"}) {
  return (
    <svg
      width={size === "sm" ? "20" : "80"}
      height={size === "sm" ? "24" : "96"}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99989 0.719971C7.43499 0.722668 4.97344 1.73114 3.14392 3.5288C1.3144 5.32646 0.262841 7.76992 0.215088 10.3344V13.5024C0.215088 16.0981 1.24624 18.5875 3.08169 20.423C4.91715 22.2584 7.40656 23.2896 10.0023 23.2896C12.598 23.2896 15.0874 22.2584 16.9229 20.423C18.7583 18.5875 19.7895 16.0981 19.7895 13.5024V10.5024C19.7838 7.90843 18.7503 5.42246 16.9154 3.58894C15.0806 1.75541 12.5938 0.723774 9.99989 0.719971ZM9.99989 19.3416C8.25308 19.3416 6.54551 18.8236 5.09309 17.8531C3.64068 16.8826 2.50866 15.5033 1.84018 13.8894C1.17171 12.2756 0.996808 10.4998 1.33759 8.78653C1.67838 7.07329 2.51954 5.49958 3.75472 4.2644C4.9899 3.02923 6.56361 2.18806 8.27685 1.84728C9.99009 1.50649 11.7659 1.68139 13.3797 2.34987C14.9936 3.01834 16.373 4.15036 17.3434 5.60277C18.3139 7.05519 18.8319 8.76277 18.8319 10.5096C18.8274 12.8499 17.8953 15.0931 16.2397 16.7473C14.5841 18.4015 12.3403 19.3318 9.99989 19.3344V19.3416Z"
        fill="#5D714E"
      />
      <path
        d="M8.46409 15.0359L5.1377 10.3295L6.14329 9.61915L8.50729 12.9623L13.8857 5.96875L14.8625 6.71995L8.46409 15.0359Z"
        fill="#5D714E"
      />
    </svg>
  );
}
