import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import _ from "lodash";

export default function SelectField({
  options,
  name,
  value,
  closeOnSelect = true,
  placeholder,
  type = "radio",
  rules,
  height,
  hidden = false,
  disabled = false,
}: any) {
  const { control, watch } = useFormContext();
  const { errors } = useFormState({ control });
  const bgColorMode = !hidden && _.get(errors, name) ? "red.50" : "blackLight";

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={_.get(watch(), name)}
        render={({ field: { ref, ...rest } }) => (
          <Menu closeOnSelect={closeOnSelect}>
            <MenuButton
              disabled={disabled}
              px={4}
              py={2}
              w="100%"
              height={"3rem"}
              _disabled={{ cursor: "not-allowed", opacity: 0.6 }}
              bg={bgColorMode}
              color="yellowLight"
              borderWidth={"1px"}
              borderColor={
                !hidden && _.get(errors, name) ? "#eb5757" : "greenExtraLight1"
              }
              transition="all 0.2s"
              _placeholder={{ color: "yellowLight" }}
              fontWeight={"normal"}
              borderRadius="full"
              // _hover={{ bg: "#073979", color: "white" }}
              _active={{ bg: "blackAlpha.200", color: "yellowLight" }}
              type="button"
            >
              <Flex as={"div"} align="center" justifyContent="space-between">
                {(value &&
                  options.find((el: any) => el.value === value)?.label) ||
                  "---"}{" "}
                <Icon as={ChevronDownIcon} />
              </Flex>
            </MenuButton>
            <MenuList
              width={"100%"}
              h={height || "auto"}
              overflowY={"auto"}
              zIndex={3}
              bg={"blackLight"}
              color="yellowLight"
              borderColor={"greenExtraLight1"}
              boxShadow="2xl"
            >
              <MenuOptionGroup {...rest} value={value} type={type}>
                {options.map((option: any) => (
                  <MenuItemOption
                    value={option.value}
                    key={option.label}
                    _hover={{ bg: "blackAlpha.400" }}
                    _checked={{bg: "blackAlpha.600"}}
                  >
                    {option?.label}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        )}
        rules={rules}
      />
    </>
  );
}
