import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Stack,
  Tag,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonGroup } from "../components/ButtonGroup";
import PageHeader from "../components/PageHeader";
import {
  fetchOneFeedback,
  togglePublishFeedback,
} from "../services/feedback.services";
import Loading from "../components/Loading";
import { IFeedback } from "./Feedback";
import moment from "moment";
import Toast from "../components/Toast";
import CheckIcon from "../assets/icons/CheckIcon";
import PageNotFound from "./NotFound";
import Dialog from "../components/DialogAlert";
import { useAuth0 } from "@auth0/auth0-react";

interface IQuestion {
  text: string;
  name:
    | "experience"
    | "equipements"
    | "recommend"
    | "support"
    | "rate"
    | "comment";
  type: string;
}
const questions: Array<IQuestion> = [
  {
    text: "How would you rate your experience with Palace?",
    name: "experience",
    type: "rate",
  },
  {
    text: "How would you rate the space and equipement?",
    name: "equipements",
    type: "rate",
  },
  {
    text: "Would you recommend Palace to a friend?",
    name: "recommend",
    type: "y/n",
  },
  {
    text: "How would you rate your customer support experience?",
    name: "support",
    type: "rate",
  },
  {
    text: "How would you rate the app?",
    name: "rate",
    type: "rate",
  },
  {
    text: "What did you like or dislike most about the app?",
    name: "comment",
    type: "text",
  },
];

export default function FeedbackDetails() {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams<{ id: string }>();

  const { data, isLoading, isError } = useQuery(
    `feedback-${id}`,
    async () => {
      const token = await getAccessTokenSilently();
      return fetchOneFeedback(id, token);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Flex direction={"column"} bg="blackLight" flex={1}>
      <PageHeader title="Received feedback" goBackUrl={"/feedback"} />
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <PageNotFound />
      ) : (
        <FeedbackDetailsTable feedback={data} />
      )}
    </Flex>
  );
}

const FeedbackDetailsTable = ({ feedback }: { feedback: IFeedback }) => {
  const { getAccessTokenSilently } = useAuth0();
  const questionAnswerType = useCallback(
    (type: string, value: string | number | boolean) => {
      switch (type) {
        case "text":
          return (
            <Textarea
              placeholder="Your comment"
              resize={"none"}
              value={String(value)}
              _hover={{}}
              _active={{}}
              _focus={{}}
              color="yellowLight"
              _placeholder={{ color: "yellowLight" }}
              name="comment"
              borderColor="greenExtraLight1"
              rounded={"2xl"}
              borderTopLeftRadius="0px"
              rows={4}
              readOnly
            ></Textarea>
          );
        case "rate":
          return (
            <ReactStars
              count={5}
              value={+value}
              edit={false}
              size={36}
              isHalf={true}
              emptyIcon={<i className="fa fa-star"></i>}
              halfIcon={<i className="fas fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#EAD3AB"
              color="gray"
            />
          );
        case "y/n":
          return <ButtonGroup value={Boolean(value)} />;
      }
    },
    []
  );

  const navigate = useNavigate();
  const toast = useToast();
  const toastIdRef = useRef<any>();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(togglePublishFeedback, {
    onSuccess: (res) => {
      const { isFeatured } = res;
      toastIdRef.current = toast({
        position: "top",
        duration: 2000,
        render: () => (
          <Toast
            toast={toast}
            message={isFeatured ? "Feedback published" : "Feadback unpublished"}
            toastIdRef={toastIdRef}
            icon={<CheckIcon />}
          />
        ),
      });
      queryClient.invalidateQueries("feedback");
      navigate("/feedback");
    },
  });

  return (
    <Container maxW={"7xl"} mt="6">
      {feedback.isFeatured && (
        <Tag mb="4" bg="yellowDark">
          Published ⭐
        </Tag>
      )}
      <HStack mb="4" justify={"space-between"} align="flex-end">
        <Stack>
          <Text fontSize="sm" textTransform={"uppercase"} color="yellowDark">
            From:
          </Text>
          <Text
            fontSize="lg"
            textTransform={"uppercase"}
            color="yellowLight"
            fontWeight={"bold"}
          >
            {feedback.name}
          </Text>
        </Stack>
        <HStack>
          <Text fontSize="sm" textTransform={"uppercase"} color="yellowDark">
            Sent on:
          </Text>
          <Text
            fontSize="sm"
            textTransform={"uppercase"}
            color="yellowLight"
            fontWeight={"bold"}
          >
            {moment(feedback.created_at).format("DD/MM/YYYY")}
          </Text>
        </HStack>
      </HStack>
      <Box border="1px solid" borderColor={"greenExtraLight1"} rounded="2xl">
        {questions.map((question: IQuestion, i: number) => (
          <Grid
            gridTemplateColumns={{
              base: "1fr",
              md: question.name === "comment" ? "1fr" : "1fr 0.5fr",
            }}
            key={question.name}
          >
            <GridItem
              borderRight={{
                base: "none",
                md: question.name === "comment" ? "none" : "1px solid",
              }}
              borderBottom={{
                base: "none",
                md: i !== questions.length - 1 ? "1px solid" : "none",
              }}
              borderColor={{ base: "red", md: "greenExtraLight1" }}
              p="4"
              display={"flex"}
              alignItems="center"
            >
              <Text color={"yellowLight"}>{question.text}</Text>
            </GridItem>
            <GridItem
              p="4"
              pb={question.name === "comment" ? "0" : "4"}
              pr={question.name === "comment" ? "0" : "4"}
              pl={question.name === "comment" ? "0" : "4"}
              display={"flex"}
              justifyContent="center"
              flexDirection={"column"}
              gap="10"
              borderBottom={i !== questions.length - 1 ? "1px solid" : "none"}
              borderColor="greenExtraLight1"
            >
              <Stack
                px={question.name === "comment" ? "4" : "0"}
                alignItems="center"
              >
                {questionAnswerType(question.type, feedback[question.name])}
              </Stack>
            </GridItem>
          </Grid>
        ))}
        <Dialog
          question="Are you sure?"
          description={
            feedback.isFeatured
              ? "This feedback will be hidden from the website's testimonials section."
              : "This feedback will be displayed in the website's testimonials section, it will include client name, comment, and the rating."
          }
          isLoading={isLoading}
          onClick={async () => {
            const token = await getAccessTokenSilently();
            return mutateAsync({ id: feedback.id, token });
          }}
        >
          <Button
            my="4"
            ml="4"
            rounded="full"
            color="yellowLight"
            borderColor={"greenLight"}
            variant="outline"
            border={"2px solid"}
            borderBottomWidth="5px"
            transition={"all 0.25s"}
            _hover={{
              bg: "darkLight",
            }}
            _active={{
              borderBottomWidth: "2px",
              borderColor: "greenDark",
            }}
          >
            {feedback.isFeatured ? "Unpublish" : "Publish"} Feedback
          </Button>
        </Dialog>
      </Box>
    </Container>
  );
};
