import {
  Stack,
  HStack,
  IconButton,
  Input,
  Box,
  Image,
  Text,
} from "@chakra-ui/react";
import { AddIcon, CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { MdUpload } from "react-icons/md";
import { useAuth0 } from "@auth0/auth0-react";

const initState = {
  file: {},
  preview: "",
  isLoading: false,
  isError: false,
  isCompleted: false,
  imgUrl: "",
};

const UploadImages = ({
  images,
  setImages,
  handleImage,
  mutateAsync,
}: {
  images: Array<any>;
  setImages: (x: any) => void;
  handleImage: (x: any, i: number) => void;
  mutateAsync: any;
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const upload = async (file: any, imageId: number) => {
    const token = await getAccessTokenSilently();
    setImages(
      images.map((el: any) =>
        el.id === imageId ? { ...el, isLoading: true } : el
      )
    );
    mutateAsync({ file: file, token, imageId });
  };

  return (
    <Stack align={"flex-start"}>
      <HStack align={"center"}>
        <Text color="yellowDark">Images</Text>
        {images.length === 0 && (
          <IconButton
            icon={<AddIcon />}
            aria-label="add image"
            bg="yellowDark"
            _hover={{}}
            size={"sm"}
            onClick={() =>
              setImages([
                ...images,
                {
                  ...initState,
                  id: Math.floor(Math.random() * 1000000000),
                },
              ])
            }
          />
        )}
      </HStack>
      {images.map((item: any, index: number) => (
        <HStack key={item.id}>
          <Input
            type="file"
            onChange={(e) => handleImage(e, item.id)}
            color="yellowDark"
          />
          {item.preview && (
            <Box position={"relative"}>
              {item.isCompleted && (
                <Box
                  position={"absolute"}
                  top="50%"
                  left="50%"
                  transform={"translate(-50%,-50%)"}
                  zIndex={2}
                >
                  <CheckIcon color="white"/>
                </Box>
              )}
              <Image
                src={item.preview}
                maxW="100px"
                maxH={"50px"}
                opacity={0.2}
                _hover={{ opacity: 1 }}
                rounded={"5px"}
              />
            </Box>
          )}
          <IconButton
            icon={<AddIcon />}
            aria-label="add image"
            bg="yellowDark"
            _hover={{}}
            size="sm"
            display={images.length - 1 === index ? "flex" : "none"}
            onClick={() =>
              setImages([
                ...images,
                {
                  ...initState,
                  id: Math.floor(Math.random() * 1000000000),
                },
              ])
            }
          />
          <IconButton
            icon={<MdUpload />}
            aria-label="upload image"
            bg="greenLight"
            _hover={{}}
            isLoading={item.isLoading}
            disabled={item.isCompleted}
            size="sm"
            onClick={() => upload(item.file, item.id)}
          />
          <IconButton
            icon={<DeleteIcon />}
            aria-label="delete image"
            colorScheme={"red"}
            size="sm"
            onClick={() =>
              setImages(images.filter((el: any) => el.id !== item.id))
            }
          />
        </HStack>
      ))}
    </Stack>
  );
};

export default UploadImages;
