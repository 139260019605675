import axiosInstance from "../utils/axiosInstance";

export const fetchContact = async (page: number, token: string) => {
  return await axiosInstance({
    method: "get",
    url: `/contact?page=${page}&limit=20`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};

export const fetchOneContact = async (id: string | undefined, token: string) => {
  return await axiosInstance({
    method: "get",
    url: `/contact/${id}`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};

export const setContactAsSeen = async ({id, token, setToRead}: {id: string, token: string, setToRead?: string}) => {
  return await axiosInstance({
    method: "patch",
    url: `/contact/${id}${setToRead ? "?setToRead=true" : ""}`,
    headers:{
      Authorization: `Bearer ${token}`
    }
  }).then((res: any) => res.data);
};
