import { HStack, useRadio, chakra, useRadioGroup, Stack, Text } from "@chakra-ui/react";
import {
  useFormContext,
  useFormState,
} from "react-hook-form";
import _ from "lodash";
import Checked from "../../assets/icons/Checked";
import Unchecked from "../../assets/icons/Unchecked";

export interface CheckboxI {
  name: string;
  label: string;
  checkBoxs?: { value: string | boolean; label: string }[];
  disabled?: boolean;
}

const CheckBoxField = ({ name, label, checkBoxs, disabled = false }: CheckboxI) => {
  const { control, clearErrors, getValues, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  //   const watchCheckBoxField = useWatch({
  //     control,
  //     // name: checkBoxs?.map(({ value }) => value)!,
  //   });

  //   useEffect(() => {
  //     if (watchCheckBoxField.includes(true)) {
  //       clearErrors(name);
  //     }
  //   }, [watchCheckBoxField]);

  function CustomRadio(props: any) {
    const { label, ...radioProps } = props
    const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } =
      useRadio(radioProps)

    return (
      <chakra.label {...htmlProps} cursor='pointer'>
        <input {...getInputProps({})} hidden />
        <HStack>
            {
                state.isChecked ? <Checked /> : <Unchecked />
            }
            <Text color={state.isChecked ? "yellowLight" : "yellowDark"}>{label}</Text>
        </HStack>
      </chakra.label>
    )
  };

  const handleChange = (value: any) => {
    setValue(name, value)
    // if(value === 'yes' || value === 'no'){
    //   setValue(name, value === 'yes')
    // } else {
    //   setValue(name, value === 'yes')
    // }
  }

  const { value, getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: getValues(name),
    onChange: handleChange,
  });

  return (
    <Stack {...getRootProps()}>
      <HStack spacing={6}>
        {checkBoxs?.map((option) => {
          return (
            <CustomRadio
              key={option.value}
              label={option.label}
              {...getRadioProps({ value: option.value.toString() })}
            />
          )
        })}
      </HStack>
    </Stack>
  )
};

export default CheckBoxField;
