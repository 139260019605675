import {
    Input,
    FormControl,
    FormLabel,
    InputGroup,
    InputLeftElement,
    FormErrorMessage,
    Icon,
    InputRightElement,
  } from "@chakra-ui/react";
  import { IoAttach } from "react-icons/io5";
  import { useController, useFormState } from "react-hook-form";
  import { useRef } from "react";
import _ from "lodash";
  
  export const FileField = ({
    name,
    placeholder,
    acceptedFileTypes,
    control,
    children,
    handleChange,
    hidden = false,
    isRequired = false,
  }: any) => {
    const inputRef = useRef<any>();
  const { errors } = useFormState({ control });
    const {
      field: { ref, value, ...inputProps },
      fieldState: { invalid },
    } = useController({
      name,
      control,
      rules: { required: isRequired },
    });
  
    return (
      <FormControl isInvalid={invalid}>
        <FormLabel htmlFor="writeUpFile">{children}</FormLabel>
        <InputGroup>
          <InputRightElement pointerEvents="none">
            <Icon as={IoAttach} fontSize="3xl" mr="4" mt="2"/>
          </InputRightElement>
          <input
            type="file"
            accept={acceptedFileTypes}
            multiple
            //    @ts-ignore
            name={name}
            ref={inputRef}
            {...inputProps}
            onChange={(e: any) => handleChange(e.target.files)}
            style={{ display: "none" }}
 
          />
          <Input
            placeholder={placeholder || "Your file ..."}
            onClick={() => inputRef.current.click()}
            // onChange={(e) => {}}
            readOnly={true}
            value={(value && value.name) || ""}
            rounded="full"
            size="lg"
            borderColor={
              !hidden && _.get(errors, name) ? "#eb5757" : "greenExtraLight1"
            }
            _placeholder={{ color: "yellowLight" }}
            color="yellowLight"
            _focus={{}}
            _hover={{}}
          />
        </InputGroup>
        <FormErrorMessage>{invalid}</FormErrorMessage>
      </FormControl>
    );
  };
  
  FileField.defaultProps = {
    acceptedFileTypes: "",
    allowMultipleFiles: false,
  };
  
  export default FileField;
  