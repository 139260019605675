import { useEffect } from "react";
import { Heading, Stack, Button, Text } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../components/Loading"

export default function Home() {
  const { loginWithRedirect, isLoading, user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("feedback");
    }
  }, [isAuthenticated]);

  if(isLoading){
    return <Loading />
  }

  return (
    <Stack
      bg="blackLight"
      minH={"100vh"}
      align="center"
      justify={"center"}
      direction="column"
      spacing={4}
    >
      <Heading color="yellowLight" as="h1" size="3xl">
        Welcome
      </Heading>

      {isAuthenticated && (
        <>
          <Text color="yellowLight">
            Hi <strong>{user?.name}</strong>, please click the button to go the
            admin panel.
          </Text>
          <Link to={"/feedback"}>Enter</Link>
        </>
      )}

      {!isAuthenticated && (
        <>
          <Text color="yellowDark">Click the button to login</Text>
          <Button
            bg="greenLight"
            onClick={() => loginWithRedirect()}
            rounded="full"
            minW="3xs"
            _hover={{
              bg: "greenDark",
              color: "beige",
            }}
            isLoading={isLoading}
          >
            Login
          </Button>
        </>
      )}
    </Stack>
  );
}
